<template>
  <el-container
    class="node-logging-profiles h-full"
    v-if="selectedAccountId && selectedAccountId !== 'all'"
  >
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Node Logging Profiles')"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
    ></page-header>
    <component v-bind:is="component"></component>
  </el-container>
  <div v-else>
    <not-scoped scope="Account" resource="Content" />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import { NODE_LOG_PROFILE } from "@/constants/nodeLoggingProfiles";
import NotScoped from "@/components/NotScoped";

export default {
  name: "NodeLoggingProfilesManageIndex",
  components: {
    NotScoped,
    PageHeader
  },

  data() {
    return {
      selectedComponent: "webhook-profiles",
      selectedIndex: null,
      navItems: [
        {
          tag: NODE_LOG_PROFILE.WEBHOOK_PROFILE,
          label: _.startCase(NODE_LOG_PROFILE.WEBHOOK_PROFILE) + "s",
          displayText: __(_.startCase(NODE_LOG_PROFILE.WEBHOOK_PROFILE) + "s"),
          active: true,
          path: "webhook-profiles",
          icon: "icon-webhook.svg"
        },
        {
          tag: NODE_LOG_PROFILE.FIREBASE_PROFILE,
          label: _.startCase(NODE_LOG_PROFILE.FIREBASE_PROFILE) + "s",
          displayText: __(_.startCase(NODE_LOG_PROFILE.FIREBASE_PROFILE) + "s"),
          active: false,
          path: "firebase-profiles",
          icon: "icon-firebase.svg",
          preserve_icon: true
        }
      ]
    };
  },

  computed: {
    component() {
      return this.importComponent(this.selectedComponent);
    },
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },

  mounted() {
    this.setProfileFilter(NODE_LOG_PROFILE.WEBHOOK_PROFILE);
  },

  methods: {
    ...mapActions("nodeLoggingProfiles", {
      setProfileFilter: "setProfileFilter"
    }),

    importComponent(path) {
      return () =>
        import("@/views/manage/node-logging-profiles/" + path + "/index.vue");
    },

    handleNavItemChanged(navItem) {
      this.setProfileFilter(navItem.tag);
      this.selectedComponent = navItem.path;
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      _.find(this.navItems, { label: navItem.label }).active = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.node-logging-profiles {
  display: flex;
  flex-direction: column;
}
</style>
